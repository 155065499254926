.socialButton {
    color: var(--text-normal);
    text-decoration: none;
    font-size: 1.5rem;

    width: 18rem;
    height: 3.5rem;

    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.socialButton__container {
    text-transform: uppercase;

    display: flex;
    justify-content: center;
    align-items: center;

    padding-top: 0.5rem;

    width: 100%;
    height: 100%;
}

.socialButton__bar {
    background-color: var(--color-primary);

    transition: width 500ms cubic-bezier(0, .5, .5, 1);
    width: 0;
    height: 0.5rem;
}

.socialButton:hover .socialButton__bar {
    width: 100%;
}
