.buttonBar {
    background-color: #00000040;
    backdrop-filter: blur(12px);

    width: 100%;
}

.buttonBar > * + * {
    margin-left: 2rem;
}

@media screen and (max-width: 768px) {
    .flex.buttonBar {
        flex-direction: column;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .buttonBar > * + * {
        margin-left: 0;
        margin-top: 1rem;
    }
}