.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex__build {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}