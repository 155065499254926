body {
    font-family: "Roboto Condensed", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: var(--text-normal);
    background-color: #BBB1D3;
}

#root {
    background-image: url("/public/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100vh;
    /* set block height */
    height: 100vh;
}

main {
    background-color: #000000d0;
}