.header {
    font-family: "Permanent Marker", cursive;
    font-size: 6rem;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .header {
        font-size: 3rem;
    }
}